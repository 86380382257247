<template>
  <section class="container">
    <b-tabs position="is-centered">
      <b-tab-item label="Compte">
        <form @submit.prevent="saveUser">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Mon compte</p>
            </header>
            <section class="modal-card-body">
              <b-field label="Nom">
                <b-input type="text" v-model="form.name" placeholder="Modifier votre nom" maxlength="50" :has-counter="false" required>
                </b-input>
              </b-field>
              <b-field label="Pseudo">
                <b-input type="text" v-model="form.nickname" placeholder="Modifier votre pseudo" maxlength="30" :has-counter="false" required>
                </b-input>
              </b-field>
              <b-field label="Adresse mail">
                <b-input type="email" v-model="form.email" placeholder="Modifier votre adresse mail" maxlength="50" :has-counter="false" minlength="8" required>
                </b-input>
              </b-field>
              <b-field label="Mot de passe">
                <b-input type="password" v-model="form.password" placeholder="Modifier votre mot de passe" maxlength="30" :has-counter="false" minlength="8" required>
                </b-input>
              </b-field>
              <b-field label="Adresse postale">
                <b-input type="text" v-model="form.address" placeholder="Modifier votre adresse postale" maxlength="60" :has-counter="false" required>
                </b-input>
              </b-field>
              <b-field label="Téléphone">
                <b-input v-model="form.phone" placeholder="Modifier votre numéro de téléphone" maxlength="25" :has-counter="false" required>
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button
                class="is-pulled-right"
                label="Mettre à jour"
                native-type="submit"
                type="is-success submit"/>
            </footer>
            <b-progress type="is-info" v-if="sending"></b-progress>
          </div>
        </form>
      </b-tab-item>
      <b-tab-item label="ID TCG">
        <form @submit.prevent="saveID">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Mes identifiants</p>
            </header>
            <section class="modal-card-body">
              <div v-for="game in games" :key="game.id" :value=" game.id ">
                <b-field
                  custom-class="is-medium"
                  horizontal>
                  <template #label>
                    {{ game.name }}
                  </template>
                  <b-input size="is-medium" type="text" v-model="playerId[game.id]" maxlength="60" :has-counter="false" placeholder="Votre identifiant"></b-input>
                </b-field>
                <br>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button
                class="is-pulled-right"
                label="Mettre à jour"
                native-type="submit"
                type="is-success submit"/>
            </footer>
            <b-progress type="is-info" v-if="sending"></b-progress>
          </div>
        </form>
      </b-tab-item>
      <b-tab-item label="Commandes">
        <template>
          <header class="modal-card-head">
            <p class="modal-card-title">Mes commandes</p>
          </header>
          <div @click="goProduct" v-if="orders.length > 0">
            <b-table
              :data="orders"
              :selected.sync="selected"
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="date"
              style="cursor: pointer;">
              <b-table-column label="No" centered v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <b-table-column label="État" centered v-slot="props">
                <span v-if="props.row.status === 'created'">Commande crée <br></span>
                <span v-if="props.row.status === 'available'">Prêt pour retrait <br></span>
                <span v-if="props.row.status === 'done'">Commande terminée <br></span>
              </b-table-column>
              <b-table-column label="Paiement" centered v-slot="props">
                <span v-if="props.row.payment === 'pending'">Paiement en attente</span>
                <span v-else>Paiement effectué <br></span>
              </b-table-column>
              <b-table-column label="Date" centered v-slot="props" field="date" sortable>
                {{ new Date(props.row.date).toLocaleDateString() }}
              </b-table-column>
            </b-table>
          </div>
          <div v-else>
            <br>
            <p class="title is-4">Vous n'avez pas encore effectué de commandes.</p>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item label="Événements">
        <template>
          <header class="modal-card-head">
            <p class="modal-card-title">Mes événements</p>
          </header>
          <div v-if="loadedEvents.length > 0">
            <b-table
              :data="loadedEvents"
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="date">
              <b-table-column label="Nom" centered v-slot="props">
                <span @click="goEvent(props.row.id)" style="cursor: pointer;">{{ props.row.name }}</span>
              </b-table-column>
              <b-table-column label="TCG" centered v-slot="props" >
                <span @click="goEvent(props.row.id)" style="cursor: pointer;">{{ props.row.game_name }}</span>
              </b-table-column>
              <b-table-column label="Prix" centered v-slot="props">
                <span @click="goEvent(props.row.id)" style="cursor: pointer;">{{ Number(props.row.price).toFixed(2) }} CHF</span>
              </b-table-column>
              <b-table-column label="Date" centered v-slot="props" field="date" sortable>
                <span @click="goEvent(props.row.id)" style="cursor: pointer;">{{ props.row.showDate }}</span>
              </b-table-column>
              <b-table-column label="Résultats" centered v-slot="props">
                <a v-if="eventPassed(props.row)" class="button has-background-success has-text-white"
                   :href="getFile(props.row.result_path)" target="_blank">Afficher les résultats</a>
                <a v-else class="button has-background-danger has-text-white" >Résultats non disponibles</a>
              </b-table-column>
            </b-table>
          </div>
          <div v-else>
            <br>
            <p class="title is-4">Vous ne participez à aucun événement.</p>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item label="Abonnement">
        <template>
          <header class="modal-card-head">
            <p class="modal-card-title">Mon abonnement</p>
          </header>
          <div v-if="user.membership_id !== null">
            <section class="hero">
              <div class="hero-body">
                <p class="title">
                  Votre abonnement est :
                  <br>
                  {{ subscriptions[user.membership_id].name }}
                </p>
                <br>
                <p class="subtitle">
                  Temps restant
                  <br>
                  {{ dateDiff(user.end_membership) }} jours
                </p>
                <p class="subtitle"> Vos avantages</p>
                <div v-html="subscriptions[user.membership_id].description" style="white-space: pre-line"></div>
              </div>
              <section class="hero">
                <div class="hero-body">
                  <p class="subtitle">
                    Voici les différents abonnement si vous souhaitez le modifier.
                  </p>
                </div>
              </section>
              <b-table :data="subscriptions" style="cursor: pointer;">
                <b-table-column label="Abonnement" centered v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column label="Description" centered v-slot="props">
                  <div v-html="props.row.description" style="white-space: pre-line"></div>
                </b-table-column>
                <b-table-column label="Prix" centered v-slot="props">
                  {{ Number(props.row.price).toFixed(2) + 'CHF' }}
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div v-if="user.membership_id === null">
            <section class="hero">
              <div class="hero-body">
                <p class="subtitle">
                  La boutique vous donne la possibilité de souscrire à un abonnement mensuel.
                  <br>
                  Cela vous permet de bénéficier de divers avantages.
                  <br>
                  Vous trouverez plus de détails ci-dessous.
                </p>
              </div>
            </section>
            <b-table :data="subscriptions">
              <b-table-column label="Abonnement" centered v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column label="Description" centered v-slot="props">
                <div v-html="props.row.description" style="white-space: pre-line"></div>
              </b-table-column>
              <b-table-column label="Prix" centered v-slot="props">
                {{ Number(props.row.price).toFixed(2) + 'CHF' }}
              </b-table-column>
            </b-table>
            <section>
              <div class="hero-body">
                <p class="subtitle">
                  Pour devenir membre, veuillez vous adresser directement à la boutique.
                </p>
              </div>
            </section>
            <b-button type="is-info" @click="$router.push({ name: 'Contact' })">DEVENIR MEMBRE</b-button>
          </div>
        </template>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>

import staticFileMixin from '@/mixins/static-file-mixin';
import notification from "@/mixins/notification";
import {DateTime} from 'luxon';

export default {
  name: 'RegisterForm',
  data: () => ({
    open: false,
    showEvent: false,
    selected: null,
    active: null,
    orders: [],
    loadedEvents: [],
    user: {},
    games: [],
    playerId: [],
    form: {
      name: null,
      password: null,
      email: null,
      nickname: null,
      address: null,
      phone: null
    },
    sending: false,
    subscriptions: [],
    defaultSortDirection: 'desc',
    sortIcon: 'arrow-up',
    sortIconSize: 'is-small',
  }),
  mixins: [staticFileMixin, notification],
  async mounted() {
    await this.$store.dispatch('getUser')
      .then((data) => {
          this.user = data;
          this.form = this.user;
        })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
    this.$store.dispatch('index', {path: 'games', data: ''})
      .then(data => {
        this.games = data.data;
      });
    this.$store.dispatch('index', {path: 'memberships', data: ''})
      .then(data => {
        this.subscriptions = data.data;
      });
    this.$store.dispatch('getPlayerId', {id: this.user.id})
      .then(data => {
        for (let i = 0; i < data.length; i++) {
          const index = data[i].pivot.game_id;
          this.playerId[index] = data[i].pivot.player_id;
        }
      })
    this.orders = await this.$store.dispatch('getOrders', {id: this.user.id})
    this.orders = this.orders.reverse()
    await this.$store.dispatch('getEvents', {id: this.user.id})
      .then(data => {
        this.loadedEvents = data
        this.loadedEvents.forEach(event => event.showDate = new Date(event.date).toLocaleDateString())
        this.loadEvents()
      })
  },
  methods: {
    dateDiff(dateSub) {
      const today = DateTime.now()
      const sub = DateTime.fromISO(dateSub)
      let result = parseInt(sub.diff(today, 'days').toObject().days)
      if (result < 0) {
        result = 0
      }
      return result+1;
    },
    loadEvents() {
      for (let i = 0; i < this.loadedEvents.length; i++) {
        for (let j = 0; j < this.games.length; j++) {
          if (this.loadedEvents[i].game_id === this.games[j].id) {
            this.loadedEvents[i].game_name = this.games[j].name;
          }
        }
      }
    },
    eventPassed(event) {
      if (event.result_path) {
        return true;
      } else {
        return false
      }
    },
    goProduct() {
      this.$router.push({name: 'getOrder', params: {id: this.selected.id}});
    },
    goEvent(myId) {
      this.$router.push({ name: 'getEvent', params: { id: myId } })
    },
    saveID() {
      this.sending = true;
      const finalPlayer = [];
      for (let i = 0; i < this.playerId.length; i++) {
        if (this.playerId[i] != null) {
          const myId = {};
          const index = i;
          myId.game = index;
          myId.id = this.playerId[i];
          finalPlayer[index] = myId;
        }
      }
      const myPlayers = finalPlayer.reduce((acc, player) =>
        ({...acc, [player.game]: {player_id: player.id}}), {})
      this.$store.dispatch('updateId', {players: myPlayers})
        .then(() => {
          this.showNotification('Modification effectuée.', 'success');
          this.open = false;
          this.sending = false;
        })
        .catch(() => {
          this.showError('Impossible de mettre à jour les identifiants.')
          this.sending = false;
        });
    },
    saveUser() {
      this.sending = true;
      const data = this.form;
      data.id = this.user.id
      this.$store.dispatch('updateAccount', data)
        .then(() => {
          this.open = false;
          this.sending = false;
          this.showNotification('Modification effectuée.', 'success');
        })
        .catch(() => {
          this.showError('Impossible de mettre à jour l\'utilisateur.');
          this.sending = false;
        });
    }
  }
};
</script>
